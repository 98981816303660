<template>
  <v-container fluid>
    <v-card elevation="5" class="mx-auto" max-width="600">
      <v-row justify="center" class="my-0 py-0 mx-2">
        <v-col cols="12" class="text-center py-3 text-decoration-underline">
          <h2>Add Member</h2>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Enter Name" v-model="name" :rules="[(v) => (!!v && v.length > 2) || 'Name is required']"
            outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Enter Phone Number" v-model="phone" :rules="[
            (v) =>
              (!!v && v.length >= 10 && /^\d+$/.test(v)) ||
              'Phone number must greater than 9 digits and contain only numbers',
          ]" outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Enter Email" v-model="email" :rules="emailRules" outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Enter Password" v-model="password" :rules="[
            (v) =>
              (v && v.length >= 6) ||
              'Password must be at least 6 characters',
          ]" outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Enter Referal ID" v-model="referalID" outlined @input="fetchParentName"></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-text-field label="Parent Name" v-model="parentName" outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="">
          <h5>Select Direction</h5>
        </v-col>
        <v-col cols="12" class="text-center py-1">
          <v-radio-group v-model="direction" row class="radio-group" color="#278EA5">
            <v-row>
              <v-radio label="Left" value="Left"></v-radio>
              <v-radio label="Right" value="Right"></v-radio>
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-text-field label="Security Pin" v-model="securityPin" :rules="[
            (v) =>
              (v && v.length >= 4) ||
              'Password must be at least 6]4 characters',
          ]" outlined></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="#278EA5" dark @click="register" :disabled="disableButton || !isEmailValid">
            <p v-if="disableButton">Registering... "></p>
            <p v-else>Register</p>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { BASE_URL, failureAlert, successAlert } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
import Swal from "sweetalert2";
export default {
  name: "NewRegistration",
  data() {
    return {
      direction: "Left",
      disableButton: false,
      name: "",
      phone: "",
      password: "",
      referalID: "",
      parentName: "",
      securityPin: "",
      email: "",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    };
  },
  async created() {
    this.referalID = localStorage.getItem("memberId");
    if (!this.referalID) {
      this.$router.push({ name: "login" });
      return;
    }
    await this.fetchParentName();
  },
  computed: {
    isEmailValid() {
      return /.+@.+\..+/.test(this.email);
    },
  },
  methods: {
    async register() {
      try {
        this.disableButton = true;

        if (this.name === "" || this.name.length < 2) {
          alert("Please enter name");
          this.disableButton = false;
          return;
        }
        if (
          this.phone === "" ||
          this.phone.length < 10 ||
          !/^\d{10}$/.test(this.phone)
        ) {
          alert("Please enter valid phone number");
          this.disableButton = false;
          return;
        }
        if (this.password === "" || this.password.length < 6) {
          alert("Please enter valid password");
          this.disableButton = false;
          return;
        }
        if (this.email === "" || !/.+@.+\..+/.test(this.email)) {
          alert("Please enter valid email");
          this.disableButton = false;
          return;
        }
        if (this.securityPin === "" || this.securityPin.length < 4) {
          alert("Please enter valid security pin");
          this.disableButton = false;
          return;
        }
        var body = {
          name: this.name,
          password: this.password,
          phoneNo: this.phone,
          referralCode: this.referalID,
          position: this.direction,
          securityPin: this.securityPin,
          email: this.email,
          isMerchant: false,
        };
        const response = await axios.post(
          `${BASE_URL}/auth/register`,
          body
        );
        if (response.status === 200 || response.status === 201) {
          this.$router.push({ name: "home" });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Registered successfully",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          successAlert("Registered successfully");
        } else {
          failureAlert("Sorry", "Failed to register");
        }
      } catch (error) {
        alert("Failed to register");
      }
      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.referalID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.referalID}`
          );
          console.log(response.data);
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.radio-group .v-input--radio-group {
  flex-direction: row !important;
}
</style>
