<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <h3 class="text-center m-5 heading">Product Details</h3>
          <v-row>
            <!-- Image Column -->
            <v-col cols="6">
              <v-img :src="productDetails.imageUrl" :alt="productDetails.name" class="product-detail-img" />
            </v-col>
            <!-- Details Column -->
            <v-col cols="6">
              <v-card-title>Name: {{ productDetails.productName }}</v-card-title>
              <v-card-subtitle>Description: {{ productDetails.description }}</v-card-subtitle>
              <v-card-text>Price: {{ productDetails.productPrice }}</v-card-text>
            </v-col>
          </v-row>
          <!-- Sellers Table -->
          <v-row>
            <v-col>
              <h3 class="text-center mb-5 heading">Seller Details</h3>
              <v-table>
                <thead>
                  <tr>
                    <th class="my-table-header">Seller Name</th>
                    <th class="my-table-header">Seller Address</th>
                    <th class="my-table-header">Seller Phone Number</th>
                    <th class="my-table-header">Quantity</th>
                    <th class="my-table-header">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <p v-if="sellerDetails.length==0" >Sorry No sellers available</p>
                  <tr v-else v-for="(seller, index) in sellerDetails" :key="index">
                    
                    <td>{{ seller.name }}</td>
                    <td>{{ seller.address }}</td>
                    <td>{{ seller.phoneNo }}</td>
                    <td>
                      <v-text-field v-model="seller.quantityToBuy" type="number" label="Quantity" />
                    </td>
                    <td>
                      <v-btn @click="openAddressDialog(seller)">Buy</v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addressDialog" overlay-class="blur-background" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Address Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="address"
                  label="Fill complete Address"
                  :rules="addressRules"
                
                  rows="10"
                  cols="30"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Phone Number"
                  v-model="phoneNo"
                  :rules="phoneNumberRules"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="text-center align-middle justify-center"><v-btn  @click="postAddress" :disabled="!isButtonEnabled || !isPhoneNumberValid || !isAddressValid" color="primary">Update</v-btn></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          
          <v-btn @click="addressDialog = false" color="grey">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="handleAddressConfirmation" :disabled="isButtonEnabled || !isPhoneNumberValid || !isAddressValid" color="primary">Proceed</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Order Summary Dialog -->
    <v-dialog v-model="orderSummaryPage" overlay-class="blur-background" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Order Summary</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p><strong>Product Name:</strong> {{ this.productDetails.productName }}</p>
                <p><strong>Quantity:</strong> {{ selectedSeller.quantityToBuy }}</p>
                <p><strong>Address:</strong> {{ this.address }}</p>
                <p><strong>Phone Number:</strong> {{ this.phoneNo }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="orderSummaryPage = false" color="grey">Cancel</v-btn>
          <v-btn @click="buyProduct" color="primary">Confirm Order</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { BASE_URL, failureAlert, successAlert } from '@/constants';
import axios from 'axios';
import { mapGetters } from "vuex";
export default {
  name: "ProductDetail",
  data() {
    return {
      productId: "",
      sellerDetails: [],
      productDetails: {},
      memberId: localStorage.getItem("memberId"),
      addressDialog: false,
      selectedSeller: null,
      confirmedAddress: {},
      address:"",
      address2:"",
      phoneNo:"",
      phoneNo2:"",
      orderSummaryPage:false,
      phoneNumberRules: [
        v => !!v || 'Phone number is required',
        v => (v && v.length >= 10) || 'Phone number must be at least 10 characters',
        v => (v && /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v)) || 'Phone number must be valid'
      ],
      addressRules: [
        v => !!v || 'Address is required',
        v => (v && v.length >= 10) || 'Address must be at least 10 characters long'
      ],
    };
  },
  computed: {
    ...mapGetters(['userDetails']),
    isPhoneNumberValid() {
    // Check if phone number matches the validation rules
    return this.phoneNumberRules.every(rule => rule(this.phoneNo) === true);
  },
  isAddressValid() {
      return this.addressRules.every(rule => rule(this.address) === true);
    },
    isButtonEnabled() {
      console.log('Address:', this.address, 'Address2:', this.address2);
    console.log('PhoneNo:', this.phoneNo, 'PhoneNo2:', this.phoneNo2);
      return this.address !== this.address2 || this.phoneNo !== this.phoneNo2;
    }
  },
  async created() {
    if (!this.memberId) {
      alert("Please login to view this page.");
      this.$router.push("/login");
      return
    } 

    if (!this.userDetails || Object.keys(this.userDetails).length === 0) {
      console.log("fetching user details");
      await this.$store.dispatch("fetchUserDetails");
    }
    this.address = this.userDetails.address;
    this.address2 = this.userDetails.address
    this.phoneNo = this.userDetails.phoneNo;
    this.phoneNo2 = this.userDetails.phoneNo
    
    this.productId = this.$route.params.id;
    await this.fetchProductDetailsAndSellers();
  },
  methods: {
    async fetchProductDetailsAndSellers() {
      try {
        const response = await axios.get(
          `${BASE_URL}/merchant/sellers/${this.productId}`
        );
        if (response.status === 200) {
          this.sellerDetails = response.data.seller_details;
          for(let i=0;i<this.sellerDetails.length;i++){
            this.sellerDetails[i]["quantityToBuy"] = 1;
          }
          this.productDetails = response.data.product_details;
        }
      } catch (error) {
        console.error(error);
      }
    },
    openAddressDialog(seller) {
      this.selectedSeller = seller;
      console.log("Selected Seller ",this.selectedSeller);
      this.addressDialog = true;
    },
    async handleAddressConfirmation() {
      try {
        await this.postAddress();
        this.addressDialog = false;
        this.orderSummaryPage = true;
      } catch (error) {
        console.error("Error while posting address:", error);
      }
    },
    async buyProduct() {
      try {
        const quantity = this.selectedSeller.quantityToBuy;
        if (!quantity || quantity <= 0) {
          alert("Please enter a valid quantity.");
          return;
        }
        // Call the buy API
        const response = await axios.post(`${BASE_URL}/user/order`, {
          merchantId: this.selectedSeller.memberId,
          productId: this.productId,
          memberId: this.memberId,
          quantity: quantity,
        });
        if (response.status === 200 || response.status === 201) {
          successAlert("Product purchased successfully");
          this.orderSummaryPage = false;
          this.addressDialog  = false;
          this.$router.push({ name: "productList" });
        } else {
          failureAlert("Error while purchasing product", response.data.message);
        }
      } catch (error) {
        console.error("Purchase error:", error);
        failureAlert("Error while purchasing product", error.response.data.message);
      }
    },

    async postAddress() {
      console.log(this.address);
      console.log(this.address2);
      try {
        this.disableButton = true;
        const body = {
          address: this.address,
          phoneNo: this.phoneNo,
        };
        const res = await axios.post(
          `${BASE_URL}/fund/add_address/${this.memberId}`,
          body
        );
        this.disableButton = false;
        if (res.status === 200 || res.status === 201) {
          successAlert("Updated successfully");
          this.address2 = this.address
          this.phoneNo2 = this.phoneNo
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        failureAlert("Sorry","Failed to update address");
      }
    },
  }
};
</script>

<style scoped>
.product-detail-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}
.product-detail-img:hover {
  filter: brightness(0.8);
}
</style>
