// store.js

import { createStore } from "vuex"; // Import createStore function from Vuex
import axios from "axios";
import { BASE_URL, constants } from "./constants";
axios.defaults.withCredentials = true;

export default createStore({
  state: {
    userDetails: {},
    userId: localStorage.getItem("memberId"),
    userName: "",
  },
  mutations: {
    SET_USER_DETAILS(state, userDetails) {
      console.log("userDetails", userDetails);
      var cf;
      if (userDetails.response.left && userDetails.response.right) {
        cf = "NA";
      } else if (userDetails.response.left) cf = "Right";
      else cf = "Left";
      state.userDetails = {
        totalTeam: userDetails.teamCount - 1,
        carryForward: cf,
        totalIncome: userDetails.totalIncome,
        availableWallet: userDetails.availableWallet,
        bankWithdrawl: userDetails.bankWithdrawl,
        availableFunds: userDetails.fund,
        // silverBonus: userDetails.silverBonus,
        // goldBonus: userDetails.goldBonus,
        // diamondBonus: userDetails.diamondBonus,
        repurchaseIncone: userDetails.totalRepurchaseIncome,
        cashbackWallet: userDetails.cashback_wallet,
        royality: userDetails.royality,
        securityPin: userDetails.securityPin,
        activatedDate: userDetails.activatedDate,
        userName: userDetails.name,
        memberId: userDetails.memberId,
        status: userDetails.status,
        address: userDetails.address,
        isMerchant:userDetails.features.includes(constants.MERCHANT),
        phoneNo:userDetails.phoneNo,
      };
    },
    SET_USER_NAME(state, userName) {
      state.userName = userName;
    },
    // Add more mutations as needed
  },
  actions: {
    async fetchUserDetails({ commit, state }, userId) {
      console.log("fetching user details for ", state.userId);
      console.log("fetching user details for ", userId);
      try {
        if (userId) state.userId = userId;
        this.userId = userId ? userId : state.userId;
        console.log("fetching user details for ", state.userId);
        const response = await axios.get(
          `${BASE_URL}/fund/user_details/${state.userId}`
        );
        // Mutation to set user details
        commit("SET_USER_DETAILS", response.data);
        commit("SET_USER_NAME", response.data.name);
      } catch (error) {
        console.error("Error fetching user details:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          console.log("Unauthorized or Forbidden, redirecting to login...");
          localStorage.clear();
          this.$router.push({ name: "login" }); 
        }
      }
    },

    async fetchAdminBankDetails() {},
  },
  getters: {
    // Add getters if needed
    userDetails: (state) => state.userDetails,
    userId: (state) => state.userId,
    userName: (state) => state.userName,
  },
});
