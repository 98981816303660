<template>
  <nav>
    <v-navigation-drawer class="navig" v-model="drawer">
      <v-list-item>
        <v-list-item-avatar>
          <v-img
            class="logo-img"
            src="https://mpbshop.s3.ap-south-1.amazonaws.com/mpblogo.jpeg"
          ></v-img>
        </v-list-item-avatar>

        <template v-slot:append>
          <p class="mr-4">MPB SHOP</p>
          <v-btn
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="drawer = !drawer"
          ></v-btn>
        </template>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item
        prepend-icon="mdi-home"
        title="Dashboard"
        value="Dashboard"
        class="mt-5 navitems"
        to="/"
      ></v-list-item>
      
      <v-list-item
        prepend-icon="mdi-receipt"
        title="Orders"
        value="Orders"
        class="mt-5 navitems"
        to="/customer_order_history"
      ></v-list-item>
      <v-list density="compact" nav>
        <v-list-group v-if="userDetails['isMerchant']" @click="activeGroup = 'Merchant'" value="Merchant">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-store"
              title="Merchant"
              value="Merchant"
              class="mt-5 navitems"
            ></v-list-item>
            <!-- ekkeda title a mana side bar lo main item name-->
          </template>

          <v-list-item
            to="/buy_requests"
            value=""
            title="Customer Requests"
            color="white"
            :class="{ 'active-background': activeGroup === 'Merchant' }"
          ></v-list-item>
          <v-list-item
            to="/product_store"
            value=""
            title="Order Products"
            color="white"
            :class="{ 'active-background': activeGroup === 'Merchant' }"
          ></v-list-item>
          <v-list-item
            to="/merchant_store"
            value=""
            title="My Store"
            color="white"
            :class="{ 'active-background': activeGroup === 'Merchant' }"
          ></v-list-item>
          <v-list-item
            to="/merchant_order_history"
            value=""
            title="Order History"
            color="white"
            :class="{ 'active-background': activeGroup === 'Merchant' }"
          ></v-list-item>
          <!--- to lo path paste chyi ah page ki elali aneydhi-->
          <!-- Add more subitems here -->
        </v-list-group>
        <v-list-group @click="activeGroup = 'Profile'" value="Profile">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-account"
              title="Visit Profile"
              value="Profile"
              class="mt-5 navitems"
            ></v-list-item>
            <!-- ekkeda title a mana side bar lo main item name-->
          </template>

          <v-list-item
            to="/profile"
            value=""
            title="View Profile"
            color="white"
            :class="{ 'active-background': activeGroup === 'Profile' }"
          ></v-list-item>
          <!--- to lo path paste chyi ah page ki elali aneydhi-->
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group @click="activeGroup = 'Member'" value="Member">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-plus"
              title="Member"
              value="Member"
              class="mt-5 navitems"
            ></v-list-item>
            <!-- ekkeda title a mana side bar lo main item name-->
          </template>

          <v-list-item
            to="/new_registration"
            title="New Registration"
            color="white"
            :class="{ 'active-background': activeGroup === 'Member' }"
          ></v-list-item>
          <!--- to lo path paste chyi ah page ki elali aneydhi-->
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group @click="activeGroup = 'Income'" value="Income">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-currency-inr"
              title="Income"
              value="Income"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/income_history"
            title="Income History"
            color="white"
            :class="{ 'active-background': activeGroup === 'Income' }"
          ></v-list-item>
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group @click="activeGroup = 'FundRequest'" value="Fund Request">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-cash-multiple"
              title="Fund Request"
              value="Fund Request"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/new_fund_request"
            title="New Request"
            color="white"
            :class="{ 'active-background': activeGroup === 'FundRequest' }"
          ></v-list-item>
          <v-list-item
            to="/fund_request_history"
            title="View Statements"
            color="white"
            :class="{ 'active-background': activeGroup === 'FundRequest' }"
          ></v-list-item>
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group @click="activeGroup = 'Activate'" value="Top Up">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-lightning-bolt"
              title="Activate"
              value="Activate"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/activate_id"
            color="white"
            title="Activate ID"
            :class="{ 'active-background': activeGroup === 'Activate' }"
          ></v-list-item>
          <v-list-item
            to="/activated_id_history"
            color="white"
            title="Activated ID's History"
            :class="{ 'active-background': activeGroup === 'Activate' }"
          ></v-list-item>
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group @click="activeGroup = 'P2P'" value="P2P Transfer">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-transfer"
              title="P2P Transfer"
              value="P2P Transfer"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/p2p_transfer"
            color="white"
            title="Transfer Fund"
            :class="{ 'active-background': activeGroup === 'P2P' }"
          ></v-list-item>
          <v-list-item
            to="/fund_transfer_history"
            title="View Transfers"
            color="white"
            :class="{ 'active-background': activeGroup === 'P2P' }"
          ></v-list-item>
          <!-- Add more subitems here -->
        </v-list-group>

        <v-list-group
          @click="activeGroup = 'WalletToFund'"
          value="Wallet to Fund"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-transfer"
              title="Wallet to Fund"
              value="Wallet to Fund"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/wallet_to_fund"
            color="white"
            title="New Transfer"
            :class="{ 'active-background': activeGroup === 'WalletToFund' }"
          ></v-list-item>
          <v-list-item
            to="/wallet_to_fund_history"
            title="Transaction History"
            color="white"
            :class="{ 'active-background': activeGroup === 'WalletToFund' }"
          ></v-list-item>
        </v-list-group>

        <v-list-group @click="activeGroup = 'MyTeam'" value="My Team">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-account-group"
              title="My Team"
              value="My Team"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/tabs"
            color="white"
            title="Grid View"
            :class="{ 'active-background': activeGroup === 'MyTeam' }"
          ></v-list-item>
          <v-list-item
            to="/tabslevel"
            color="white"
            title="Level Team"
            :class="{ 'active-background': activeGroup === 'MyTeam' }"
          ></v-list-item>
          <v-list-item
            to="/direct_team"
            color="white"
            title="My Direct Team"
            :class="{ 'active-background': activeGroup === 'MyTeam' }"
          ></v-list-item>
        </v-list-group>

        <v-list-group @click="activeGroup = 'Withdrawl'" value="Withdrawl">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-bank-transfer-out"
              title="Withdrawl"
              value="Withdrawl"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/new_withdrawl"
            color="white"
            title="New Withdraw"
            :class="{ 'active-background': activeGroup === 'Withdrawl' }"
          ></v-list-item>
          <v-list-item
            to="/withdrawl_history"
            title="Withdrawls History"
            color="white"
            :class="{ 'active-background': activeGroup === 'Withdrawl' }"
          ></v-list-item>
        </v-list-group>

        <v-list-group @click="activeGroup = 'Support'" value="Support">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-help-circle"
              title="Support"
              value="Support"
              class="mt-5 navitems"
            ></v-list-item>
          </template>

          <v-list-item
            to="/new_support_request"
            value=""
            title="New Request"
            color="white"
            :class="{ 'active-background': activeGroup === 'Support' }"
          ></v-list-item>
          <v-list-item
            to="/support_history"
            color="white"
            value=""
            title="View History"
            :class="{ 'active-background': activeGroup === 'Support' }"
          ></v-list-item>
        </v-list-group>
        <v-list-item
          prepend-icon="mdi-logout"
          title="Sign Out"
          value="signOut"
          class="mt-5 navitems"
          @click="signOut"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar class="navig1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <marquee behavior="scroll" direction="left" scrollamount="5">
        <span>Welcome to MPB SHOP</span>
      </marquee>
      <v-toolbar-title> </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-main></v-main>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      activeGroup: "",
      showNotifications: false,
      dnd: false,
      leadrequests: [],
      count: "0",
      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
    };
  },
 async created() {
    if (!this.userDetails || Object.keys(this.userDetails).length === 0) {
      console.log("fetching user details");
      await this.$store.dispatch("fetchUserDetails");
    }
    console.log("User Details", this.userDetails);
    console.log("isMerchant",this.userDetails.isMerchant);
  },
  computed: {
    ...mapGetters(["userDetails"]),
  },
  methods: {
    signOut() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.navig1 {
  background-color: #16003b;
  color: white;
}
.navig {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #16003b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #16003b;
  color: white;
  display: flex;
  align-items: center;
  height: 64px;
}

.message-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.message-container span {
  padding-left: 150px;
}

.logo-img {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}
.menuicon {
  color: black;
}
.navigation {
  background-color: #250361;
}
.navi {
  color: white;
}

.list-item-custom .v-icon {
  color: #b51b75;
}
.not {
  z-index: 3;
}
.noti {
  height: 5rem;
  width: 20rem;
}
.active-background {
  background-color: #288df1;
}
</style>
