<template>
  <v-container class="mx-auto bg-antiquewhite">
    <!-- <v-row class="grid-cols-6 items-center justify-items-center mx-auto">
      <v-col cols="12" md="12" lg="12">
        <div>
          <h4 class="text-2xl md:text-3xl font-bold mb-4">Start Your Own Business</h4>
          <p class="text-base md:text-lg sm:text-sm mb-4">Don't let fear hold you back from achieving your dreams. Start your own business with us
            today and begin building a brighter financial future. With our proven system, comprehensive training, and
            ongoing support, you'll be well on your way to success.
            Our platform allows you to earn money by helping others save money on their daily expenses. Our platform
            provides a essential services that everyone needs, making it easy to sell and promote product-based platform
            offers a range of high-quality products that are in demand.
            Don't wait for the right opportunity—it's already here! Join us now and Imagine earning money
            all while working from the comfort of your home. With our unique products, Product Base Platform, You'll have
            access to multiple income streams, a network of motivated partners, and a proven system designed to help you
            succeed, even if you're new to business.</p>
        </div>
      </v-col>
    </v-row> -->
    <v-row class="grid-cols-3 items-center justify-items-center mx-auto">
      <!-- Left Column -->
      <v-col cols="12" md="6" lg="6">
        <div>
          <h4 class="text-2xl md:text-2xl font-bold mb-4">Start Your Own Business</h4>
          <p class="text-1.8xl">Don't let fear hold you back from achieving your dreams. Start your own business with us
            today and begin building a brighter financial future. With our proven system, comprehensive training, and
            ongoing support, you'll be well on your way to success.
            Our platform allows you to earn money by helping others save money on their daily expenses. Our platform
            provides a essential services that everyone needs, making it easy to sell and promote product-based platform
            offers a range of high-quality products that are in demand.
            Don't wait for the right opportunity—it's already here! Join us now and Imagine earning money
            all while working from the comfort of your home. With our unique products, Product Base Platform, You'll have
            access to multiple income streams, a network of motivated partners, and a proven system designed to help you
            succeed, even if you're new to business.</p>
        </div>
      </v-col>

      <!-- Right Column -->
      <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-items-center">
        <v-img alt="image" src="https://vcanshoppe.s3.ap-south-1.amazonaws.com/main_image.jpg"
          class="responsive-img mb-4" />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "DashboardComponent",
  methods: {
    buyNow() {
      // Handle Buy Now button click
    }
  }
};
</script>

<style scoped>
.my-container {
  background-color: antiquewhite;
  max-width: 100%;
  max-height: fit-content;
}



@media (min-width: 768px) {
  .text-h1 {
    font-size: 4rem;
  }
}

@media (min-width: 1024px) {
  .text-h1 {
    font-size: 5rem;
  }
}

.text-paragraph {
  color: black;
  font-size: 1rem;
  /* Adjust size as needed */
}

@media (min-width: 768px) {
  .text-paragraph {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .text-paragraph {
    font-size: 1.5rem;
  }
}

.button {
  font-weight: bold;
  font-size: 1rem;
  color: black;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .button {
    font-size: 1.5rem;
  }
}

.button:hover {
  background-color: orange;
  /* Adjust hover color as needed */
}

.responsive-img {
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
}

@media (max-width: 768px) {
  .responsive-img {
    max-width: 300px;
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .responsive-img {
    max-width: 250px;
    max-height: 250px;
  }
}

.mb-4 {
  margin-bottom: 1rem;
}</style>
